<template>
<div>
    <v-container>
        <v-row>

            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Listado de Gestiòn" class="tran"></s-toolbar>
                <v-card>

                    <v-row style="margin:auto">
                        <v-col cols="12">
                            <v-card class="mx-auto my-1" width="100%">
                                <v-row justify="center" style="margin-top: 4px; margin-left: 10px; margin:auto">
                                    <v-col cols="2" lg="2" md="2">
                                        <v-btn elevation="0" block class="mt-4" small color="info" @click="btnSearch()">
                                            Buscar
                                        </v-btn>

                                    </v-col>
                                    <v-col cols="2" lg="2" md="2" class="pr-10">
                                        <v-btn elevation="0" block class="mt-4" :color="'success'" small @click="downloadReport()"><i style="font-size: 20px" class="fas fa-file-pdf"></i> Exportar</v-btn>
                                    </v-col>
                                    <v-col cols="4" lg="4" md="4">
                                        <s-select-definition :def="1131" v-model="objMounth" label="Seleccione mes"></s-select-definition>
                                    </v-col>
                                    <v-col cols="4" lg="2" md="3">
                                        <s-text label="Ingrese año" number v-model="txtYear"></s-text>
                                    </v-col>
                                    
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-data-table :height="itemsGestion.length > 0 ? '400px': 'auto'" disable-sort disable-filtering dense :headers="headers" :items="itemsGestion"
                            :footer-props="{
                                    showCurrentPage: true,
                                    showFirstLastPage: true,
                                    itemsPerPageOptions: [5, 10, 50, 100],
                                }">
                            </v-data-table>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="processing" v-if="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Por favor espere.
                    <v-progress-linear indeterminate color="white" class="mb-0 mt-3"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al realizar busqueda <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</div>
</template>

<script>
import _sAccounting from '@/services/Accounting/ConChargeMasive.js'
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";

export default {

    data() {
        return {
            processing: false,
            messageProcessing: "",
            objMounth: null,
            txtYear: this.$fun.getYear(),
            dateFilter: null,

            items: [],
            itemsGestion: [],
            headers: [{
                    text: "Fecha",
                    value: "CobDateRegister",
                    width: 120
                },
                {
                    text: "Descripciòn",
                    value: "CobDescription",
                    width: 500

                },
                {
                    text: "Usuario",
                    value: "CobUserName",
                    width: 100
                },
                {
                    text: "Codigo Venta",
                    value: "CobCodSales",
                    width: 100
                },
                {
                    text: "Lote",
                    value: "CobNroLot",
                    width: 80
                },
                {
                    text: "Proyecto",
                    value: "CobProjectName",
                    width: 350
                },
                {
                    text: "Modo",
                    value: "TypeModName",
                    width: 100
                },
                {
                    text: "Gestiòn",
                    value: "TypeGestionName",
                    width: 300
                },

            ],
            txtDocIdentidad: ""
        };
    },


    methods: {

        btnSearch() {
            var xFlag = 1;

            if (this.$fun.isAdmin()) {
                xFlag = 1;
            } else {
                xFlag = 2;
            }

            var obj = {
                QryParamsArray: "Mounth, Year, UsrName, xFlag",
                QryParamsArrayValue: this.objMounth + "," + this.txtYear + "," + localStorage.getItem("UsrName") + "," + xFlag, //this.DateBegin + "," + this.DateEnd + "," + this.number;
                QryNameProcedure: "CobSearchCustomerGestion_L"
            }

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(obj, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            if (resp.data.length > 0) {
                                resp.data.forEach(element => {
                                    element.CobDateRegister = element.CobDateRegister == null ? "" : this.$moment(element.CobDateRegister).format(this.$const.FormatDateTimeDB);
                                });
                            }

                            this.itemsGestion = resp.data
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        downloadReport() {
            var xFlag = 1;

            if (this.$fun.isAdmin()) {
                xFlag = 1;
            } else {
                xFlag = 2;
            }

            var obj = {
                QryParamsArray: "Mounth, Year, UsrName, xFlag",
                QryParamsArrayValue: this.objMounth + "," + this.txtYear + "," + localStorage.getItem("UsrName") + "," + xFlag, //this.DateBegin + "," + this.DateEnd + "," + this.number;
                QryNameProcedure: "CobSearchCustomerGestion_R"
            }
            name = "GESTIÒN DE COBRANZA"

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(obj, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                name
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        }
    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
